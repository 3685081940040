.App {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* svg {
  margin-top: 50px;
} */
svg #letters path {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  fill-opacity: 0;
}

.price p{
  font-size: 22px;
  margin-top: 10px;
  font-weight: 400;

}

.How_details {
  border: 2px solid yellow;
  border-radius: 25px;
  height: 800px;
  max-height: 800px;
  margin-top: 90px;
}
.How_details h3{
  text-align: center;
  padding-bottom: 30px;
}
.h2_class{
  text-align: center;
  padding-top: 80px;
}

.desktop_view {
  font-size: 20px;
   margin-bottom: 50px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 600px) {
  .How_details {
    height : 800px;
    min-height: 1400px;
  }
  .How_details img{
    width: 100%;
    border: 1px solid gray;
  }
}